import React from "react";

const Education = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.6822 25.3015C29.6822 28.6459 25.4218 31.3571 20.1663 31.3571C14.9108 31.3571 10.6504 28.6459 10.6504 25.3015"
        stroke={iconColor}
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M10.6509 25.3015L10.6504 20.5M29.6827 20.5V25.3015"
        stroke={iconColor}
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M37.0215 15.8902C37.4078 16.0692 37.4078 16.6183 37.0216 16.7974L20.2082 24.5942C20.0748 24.6561 19.9209 24.6561 19.7875 24.5942L2.97815 16.7974C2.59196 16.6183 2.59201 16.0693 2.97824 15.8902L19.7876 8.09748C19.9209 8.03565 20.0748 8.03565 20.2081 8.09746L37.0215 15.8902Z"
        stroke={iconColor}
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M34.8949 23.1956C35.0729 22.806 35.6264 22.806 35.8044 23.1956L36.8044 25.3839C36.8648 25.5159 36.8647 25.6677 36.8043 25.7997L35.8043 27.9853C35.6262 28.3745 35.0731 28.3745 34.895 27.9853L33.8949 25.7997C33.8345 25.6677 33.8345 25.5159 33.8948 25.3839L34.8949 23.1956Z"
        fill={iconColor}
        stroke={iconColor}
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M35.3486 17.3176V22.6352"
        stroke={iconColor}
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default Education;
