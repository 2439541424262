import React, { Suspense, useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import Sidebar from "./Components/Sidebar";
import { useLocation } from "react-router";
import { useTranslation } from 'react-i18next';
import LoaderPost from "../../Components/Loader";
import * as constants from "../../Utils/constants";
import InvestmentSidebar from "./Components/Sidebar/InvestmentSidebar";
import { Link } from "react-router-dom";
import { getBatteryLevel, getDeviceInfo, getDeviceName, getLocation, initFirebase, showModal } from "../../Utils";
import Bottom from "./Components/Sidebar/Bottom";
import * as pkg from '../../../package.json'
import { BASE_URL } from "../../Utils/url";

const Loader = () => (
  <></>
)

const AuthenticatedLayout = (props) => {

  const { t } = useTranslation();
  const [investmentAccountData, setInvestmentAccountData] = useState();
  const { route } = props;
  const location = useLocation();
  const [user, setuser] = useState();
  const [headerTitle, setheaderTitle] = useState("")
  const [isNotification, setisNotification] = useState(true)
  const [isHeaderIcons, setisHeaderIcons] = useState(true)
  const [isSidebar, setisSidebar] = useState(true)
  const [isMobileNav, setisMobileNav] = useState(false)
  const [helpIcon, sethelpIcon] = useState(true)
  const [languageIcon, setlanguageIcon] = useState(true);
  const [superpowerIcon, setSuperpowerIcon] = useState(false);
  const [investmentSidebar, setinvestmentSidebar] = useState(false)
  const [notificationModal, setnotificationModal] = useState(null);
  const [pageClass, setpageClass] = useState(``)
  const [showHeader, setshowHeader] = useState(window.sessionStorage.getItem("__bank"));
  const [mcount, setmcount] = useState(0)
  const [investmentBodyColor, setinvestmentBodyColor] = useState('')

  const [loc, setloc] = useState();
  const [battery, setbattery] = useState();
  const [device, setdevice] = useState();
  const [fcmtoken, setfcmtoken] = useState();
  const [milestoneCompleted, setMilestoneCompleted] = useState(false);
  const [featureTogglesResponse, setFeatureTogglesResponse] = useState([]);
  const [isInvestmentOnly, setIsInvestmentOnly] = useState(false);

  useEffect(() => {

    if (!props.state.user) {
      props._user(constants.V6USERS + "?module=global", "", (res) => {
        setuser(res);
      })
    } else {
      setuser(props.state.user);
    }
  }, [props && props.state])

  useEffect(() => {
      if(featureTogglesResponse && featureTogglesResponse.length && !window.location.pathname.includes('parent/investments') && !window.location.pathname.includes('parent/setting/dashboard')){
        const isInvestmentsOnly = !featureTogglesResponse.find(e => e === 'InvestmentsOnly');
        setIsInvestmentOnly(isInvestmentsOnly ? true : false)
        if(isInvestmentsOnly){
          getInvestmentAccount();
          sessionStorage.setItem('puserId', user.self.userId);
        }else{
          generateInvestmentBodyColor();
        }
      }
  }, [featureTogglesResponse])

  useEffect(() => {
    if (props.state && props.state.theme.values) {
      window.document.title = props.state.theme.values.tenantFullName;
      setshowHeader(props.state.theme.values.showHeader)
      // setprimaryColor(props.state.theme.values.primaryColor)
    }
  }, [props.state])

  useEffect(() => {
    if (props.state.theme.values.primaryColor) {
      milestones()
    }
  }, [props && props.state && props.state.theme])

  useEffect(() => {
    if (investmentBodyColor) {
      getInvestmentAccount();
    }
  }, [investmentBodyColor])

  useEffect(() => {

    if (superpowerIcon) {
      milestones_count();
    }

  }, [superpowerIcon])

  useEffect(() => {

    if (location.pathname) {
      logoutAfter();
      let filterRoutes = route.routes.filter(e => e.path === location.pathname);
      if (filterRoutes.length === 0) {
        filterRoutes = route.routes.filter(e => (e.path) ? location.pathname.indexOf(e.path.split(":")[0]) !== -1 : false);
      }

      if (filterRoutes.length > 0 && filterRoutes[0].params) {
        getFeatureFlag();
        setheaderTitle(filterRoutes[0].params.title);
        setisMobileNav(filterRoutes[0].isMobileNav);
        setisNotification(filterRoutes[0].params.notificationBell);
        setlanguageIcon(filterRoutes[0].params.languageIcon);
        setSuperpowerIcon(filterRoutes[0].params.superpowerIcon);
        sethelpIcon(filterRoutes[0].params.helpIcon);
        setisHeaderIcons((filterRoutes[0].params.headerIcons === false) ? filterRoutes[0].params.headerIcons : true);
        setisSidebar((filterRoutes[0].params.sidebar === false) ? filterRoutes[0].params.sidebar : true);
        setinvestmentSidebar((filterRoutes[0].params.investmentSidebar === true) ? filterRoutes[0].params.investmentSidebar : false);
        setpageClass('page' + filterRoutes[filterRoutes.length - 1].path.replace(/:/g, '').replace(/\//g, '-'));

        document.querySelectorAll(`body`).forEach(e => e.classList.remove(`page-register`));
        getInvestmentAccount();

      } else {
        setheaderTitle(``);
      }
    }

  }, [location.pathname])

  useEffect(() => {
    if (notificationModal === true) {
      showModal("notificationModal");
      // setnotificationModal(false);
    }
    if (notificationModal === false) {
      setnotificationModal(true);
    }
  }, [notificationModal])

  useEffect(() => {
    sessionStorage.setItem('loginType', 'parent')
    initFirebase(setfcmtoken);
    getLocation(setloc);
    getBatteryLevel(setbattery);
    //getDeviceName();
    getDeviceInfo(setdevice);

  }, [])

  useEffect(() => {
    if (loc && device && battery && fcmtoken) {
      const deviceToken = {
        ...loc, ...device, ...battery, ...fcmtoken,
        provider: "FCM", app_version: "2.1.3",
        app_build_number: "2.1.3",
        is_simulator: false,
        ipAddress: "127.0.0.1"
      };
      console.log(deviceToken);
    }
  }, [loc, device, battery, fcmtoken])

  const generateInvestmentBodyColor = () => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(props.state.theme.values.primaryColor);
    if (!result) { return }
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    const color = `rgba(${r}, ${g}, ${b}, 0.125)`;
    setinvestmentBodyColor(color)
  }

  const milestones = () => {
    props._list(constants.MILESTONES + '?v=' + Date.now(), null, (res) => {
      let activated = [true, false, false, false, false, false, false, false,false,false,false,false,false];
      activated[1] = res.find(e => e.milestoneId === 2).isCompleted;
      activated[2] = res.find(e => e.milestoneId === 3).isCompleted;
      activated[3] = res.find(e => e.milestoneId === 1).isCompleted;
      activated[4] = res.find(e => e.milestoneId === 5).isCompleted;
      activated[5] = res.find(e => e.milestoneId === 6).isCompleted;
      activated[6] = (res.find(e => e.milestoneId === 4 && e.isCompleted === true) ? res.find(e => e.milestoneId === 4 && e.isCompleted === true).isCompleted : false);
      activated[7] = res.find(e => e.milestoneId === 7).isCompleted;
      activated[8] = res.find(e => e.milestoneId === 9).isCompleted;
      activated[9] = res.find(e => e.milestoneId === 10).isCompleted;
      activated[10] = res.find(e => e.milestoneId === 11).isCompleted;
      activated[11] = res.find(e => e.milestoneId === 12).isCompleted;
      activated[12] = res.find(e => e.milestoneId === 8).isCompleted;
      setMilestoneCompleted(activated.every((e) => e == true))
    })
  }

  const milestones_count = () => {
    props._list(constants.MILESTONES_COUNT, null, res => {
      setmcount(res.remainingCount);
    }, err => { }, false);
  }

  const toggleSidebar = () => {
    document.querySelector('.parent-left-sidebar').classList.toggle('active');
  }

  const getFeatureFlag = () => {
    if (props.state && props.state.crud.featuretoggle) { return }
    
    props._list(constants.FEATURE_FLAG_V4, null, res => {
      setFeatureTogglesResponse(res);
    }, err => { }, false)

    props._feature(constants.FEATURE_FLAG_V2, null, res => {
    }, err => { }, false)
  }

  const logoutAfter = () => {
    var idleInterval;

    if (idleInterval) {
      clearInterval(idleInterval);
    }

    document.removeEventListener(`mousemove`, () => { });
    document.removeEventListener(`keypress`, () => { });

    var idleTime = 0;

    document.addEventListener(`mousemove`, () => {
      idleTime = 0;
    })

    document.addEventListener(`keypress`, () => {
      idleTime = 0;
    })

    const timerIncrement = () => {
      idleTime = idleTime + 1;
    }

    idleInterval = setInterval(timerIncrement, 60000);

  }

  const getInvestmentAccount = () => {
    const isInvestmentsOnly = !featureTogglesResponse.find(e => e === 'InvestmentsOnly');
    if(!isInvestmentsOnly){
      if (!investmentBodyColor) { return }
    }
    let apiUrl;
    let plocData = sessionStorage.getItem('puserId');
    let clocData = sessionStorage.getItem('userId');
    if (plocData != null || plocData != undefined) {
      apiUrl = constants.INVESTMENTS_CREATE
    }
    else {
      apiUrl = constants.INVESTMENTS_CREATE + `?wardId=${clocData}`
    }
    props._investment_account(apiUrl, null, (res) => {
      const currentUser = sessionStorage.getItem('puserId') || sessionStorage.getItem('userId');
      const d = res.filter((e) => (e.userGuid == currentUser))[0];
      
      // d.mode = "None";
      sessionStorage.setItem('practiceMode', d.mode);
      setInvestmentAccountData(d);
      // if(d.mode === "None"){
      //   props.history.push(`/parent/investments`);
      // }else{
      //   props.history.push('/parent/investments/dashboard');
      // }
      if (props.location.pathname.includes('/parent/investments') && d.mode === 'Practice') {
        document.querySelectorAll(`body`).forEach(e => e.classList.add(`parent-module`));
        document.querySelectorAll(`body`).forEach(e => e.classList.add(`practice-mode`));
        document.querySelectorAll(`body`).forEach(e => e.style.backgroundColor = investmentBodyColor);
        // document.querySelectorAll('.tenant-menubar.investment').forEach(e => e.style.backgroundColor = '#e6e9e9');
        
      }
      else {
        document.querySelectorAll(`body`).forEach(e => e.classList.remove(`parent-module`));
        document.querySelectorAll(`body`).forEach(e => e.classList.remove(`practice-mode`));
        document.querySelectorAll(`body`).forEach(e => e.style.backgroundColor = '#ffffff');
        // document.querySelectorAll('.tenant-menubar').forEach(e => e.style.backgroundColor = '#ffffff');
      }
      if (props.location.pathname.includes('/parent/investments/child/settings')) {
        document.querySelectorAll(`body`).forEach(e => e.classList.remove(`parent-module`));
        document.querySelectorAll(`body`).forEach(e => e.classList.remove(`practice-mode`));
        document.querySelectorAll(`body`).forEach(e => e.style.backgroundColor = '#ffffff');
        // document.querySelectorAll('.tenant-menubar').forEach(e => e.style.backgroundColor = '#ffffff');
      }

    }, err => {
    })
  }

  return (
    <>
      {user &&
        <>
          {showHeader ?
            <header className="header-chorecheck">
              <div className="navbar-container">
                <div className="logo">
                  <img src={props.state.theme.values.logo} className="cursor_pointer" alt="rego logo" onClick={() => window.location.reload()} />
                  <a href={`https://mazoola.co/download-the-app/`} target="_blank" rel="noreferrer">
                    <button className="btn-gradient">
                      {t('download_app')}
                    </button>
                  </a>
                </div>
              </div>
            </header>
            : null}
          <div className="main-container">
            <div className={`dashboard-wrapper ${isSidebar ? 'sidebar' : 'nosidebar'}`}>
              <div className="toggleSidebar" onClick={toggleSidebar}><img src={`${BASE_URL.CDN}/tenants/${props.state.theme.values.tenantFullName}/web/public/assets/images/menuBar.svg`} alt="menubar" /></div>

              {isSidebar ?
                <>
                {props.state.theme.values.navLocation ? (
                    showHeader && (props.state.theme.values.navLocation == 'iFrame-Left' || props.state.theme.values.navLocation == 'Page-Top' || props.state.theme.values.navLocation == 'Page-Left') &&
                    <Sidebar {...props} showHeader={showHeader} />
                  ) : 
                  showHeader && <Sidebar {...props} showHeader={showHeader} />
                }

                  <div className={`dashboard-right-article ${pageClass} ${showHeader ? 'with-Header' : 'no-sidebar'}`}>
                    {!showHeader && props.state.theme.values.navLocation === 'iFrame-Top' && 
                      <div className="tenant-menubar">
                        <ul>
                          <li className={`${location.pathname === "/parent/dashboard" ? 'active' : ''}`}>
                            <Link to="/parent/dashboard">{t('my_family')}</Link>
                          </li>
                          <li className={`${location.pathname === "/parent/timeline" ? 'active' : ''}`}>
                            <Link to="/parent/timeline">{t('timeline')}</Link>
                          </li>
                          <li className={`${location.pathname === "/parent/education" ? 'active' : ''}`}>
                            <Link to="/parent/education">{t('education')}</Link>
                          </li>
                          <li className={`${location.pathname === "/parent/wallet" ? 'active' : ''}`}>
                            <Link to="/parent/wallet">{t('wallet')}</Link>
                          </li>
                          <li className={`${location.pathname === "/parent/shopping" ? 'active' : ''}`}>
                            <Link to="/parent/shopping">{t('shopping')}</Link>
                          </li>
                          <li className={`${location.pathname === "/parent/profile" ? 'active' : ''}`}>
                            <Link to="/parent/profile">{t('profile')}</Link>
                          </li>
                          <li className={`${location.pathname === "/parent/setting/dashboard" ? 'active' : ''}`}>
                            <Link to="/parent/setting/dashboard">{t('settings')}</Link>
                          </li>
                        </ul>
                      </div>
                    }
                    <LoaderPost identifier={'0'} />

                    {isHeaderIcons === false ?
                      null
                      :
                      <>

                        {/* <h1 className={`dashboard-heading ${isNotification && helpIcon && languageIcon?'':'none'}`}>{t(headerTitle)} */}
                        <h1 className='dashboard-heading'>{t(headerTitle)}

                          <div className="heading-right-icon-list ml-auto">
                            {superpowerIcon && !milestoneCompleted &&
                              <Link to={`/parent/superpowers`} className="superpower-icon">
                                {props.state.theme.values.tenantFullName && <img src={`${BASE_URL.CDN}/tenants/${props.state.theme.values.tenantFullName}/web/public/assets/images/bulb.svg`} alt="Superpower" className="img-fluid" />}
                                {mcount < 1 ?
                                  <></>
                                  : <span className="dot">{mcount}</span>}
                              </Link>
                            }
                            {helpIcon ?
                              <span className="menuHelp">
                                <Link to={'/parent/help'} rel="noreferrer">
                                  {/* <span className="fa fa-question-circle-o"></span> */}
                                  {props.state.theme.values.tenantFullName && <img alt="info circle" src={`${BASE_URL.CDN}/tenants/${props.state.theme.values.tenantFullName}/web/public/assets/images/info-circle-dark.svg`} />}
                                </Link>
                              </span>
                              : null}
                            {isNotification ?
                              <span className="dashboard-notification-wrapper">

                                <a onClick={(e) => { e.preventDefault() }} href="!#">
                                  <img alt="bell icon" src={`${BASE_URL.CDN}/tenants/${props.state.theme.values.tenantFullName}/web/public/assets/images/bell.svg`} onClick={() => { setnotificationModal(!notificationModal); }} />
                                  {/* <span className="fa fa-bell" onClick={() => { setnotificationModal(!notificationModal); }}></span> */}
                                  <span className="notification-unred-"></span>
                                </a>
                              </span>
                              : null}

                            {languageIcon ?
                              <span className="language-changer">
                                <ul className="navbar-nav ml-auto text-right">
                                  <li className="nav-item dropdown topLanguageChange">
                                    <a className="nav-link dropdown-toggle" href={`!#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      {window.sessionStorage.getItem("lang") ? window.sessionStorage.getItem("lang") : constants.FALLBACK_LANG}
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                      <a href={`!#`} className="dropdown-item" onClick={e => {
                                        e.preventDefault()
                                        window.sessionStorage.setItem(constants.LOCALSTORAGE.lang, "en");
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 100)
                                      }}>
                                        <img src="../assets/images/Mazoola/flags/USFlag.svg" width="14px"/>English
                                      </a>
                                      <a href={`!#`} className="dropdown-item" onClick={e => {
                                        e.preventDefault()
                                        window.sessionStorage.setItem(constants.LOCALSTORAGE.lang, "es")
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 100)
                                      }}>
                                        <img src="../assets/images/Mazoola/flags/ESFlag.svg" width="14px"/>Español
                                      </a>
                                      <a href={`!#`} className="dropdown-item" onClick={e => {
                                        e.preventDefault()
                                        window.sessionStorage.setItem(constants.LOCALSTORAGE.lang, "fr")
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 100)
                                      }}>
                                        <img src="../assets/images/Mazoola/flags/FRFlag.svg" width="14px"/>Français
                                      </a>
                                      <a href={`!#`} className="dropdown-item" onClick={e => {
                                        e.preventDefault()
                                        window.sessionStorage.setItem(constants.LOCALSTORAGE.lang, "no")
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 100)
                                      }}>
                                        <img src="../assets/images/Mazoola/flags/NOFlag.svg" width="14px"/>Norsk
                                      </a>
                                      
                                    </div>
                                  </li>
                                </ul>
                              </span>
                              : null}
                          </div>
                        </h1>
                      </>
                    }
                    <Suspense
                      fallback={<Loader />}
                    >

                      {renderRoutes(route.routes)}

                    </Suspense >
                  </div>

                </>
                : <>

                  {investmentSidebar ?
                    <>
                    {showHeader &&
                      <InvestmentSidebar {...props} />
                    }
                        <div className={`dashboard-right-article ${pageClass} ${showHeader ? 'with-Header' : 'no-sidebar'}`}>
                          {!showHeader && 
                            <div className={`tenant-menubar ${!isInvestmentOnly && 'investment'}`}>
                              <ul> 
                                {featureTogglesResponse.find(e => e === 'InvestmentsOnly') && 
                                  <li className={`${location.pathname === "/parent/dashboard" ? 'active' : ''}`}>
                                    <Link to="/parent/dashboard">{t('my_family')}</Link>
                                  </li>
                                }
                                <li className={`${location.pathname === "/parent/investments/dashboard" ? 'active' : ''}`}>
                                  <Link to="/parent/investments/dashboard">{t('investments')}</Link>
                                </li>
                                <li className={`${location.pathname === "/parent/investments/timeline" ? 'active' : ''}`}>
                                  <Link to="/parent/investments/timeline">{t('timeline')}</Link>
                                </li>
                                <li className={`${location.pathname === "/parent/investments/research" ? 'active' : ''}`}>
                                  <Link to="/parent/investments/research">{t('research')}</Link>
                                </li>
                                <li className={`${location.pathname === "/parent/investments/watchlist" ? 'active' : ''}`}>
                                  <Link to="/parent/investments/watchlist">{t('investment_watchlist.watchlist')}</Link>
                                </li>
                                <li className={`${location.pathname === "/parent/investments/reports" ? 'active' : ''}`}>
                                  <Link to="/parent/investments/reports">{t('reports')}</Link>
                                </li>
                                <li className={`${location.pathname === "/parent/parentInvestmentSettings" ? 'active' : ''}`}>
                                  <Link to="/parent/parentInvestmentSettings">{t('settings')}</Link>
                                </li>
                              </ul>
                            </div>
                          }
                        <LoaderPost identifier={'0'} />
                        <Suspense
                          fallback={<Loader />}
                        >

                          {renderRoutes(route.routes)}

                        </Suspense >
                      </div>
                    </>

                    :
                    <>
                      <div className={`dashboard-right-article ${pageClass}`}>
                        <LoaderPost identifier={'0'} loaderclass={`center-loader`} />
                        <Suspense
                          fallback={<Loader />}
                        >

                          {renderRoutes(route.routes)}

                        </Suspense >
                      </div>
                    </>
                  }
                </>
              }
            </div>

            <Bottom {...props} showHeader={showHeader} isMobileNav={isMobileNav} investmentSidebar={investmentSidebar} />
          </div>
        </>
      }
    </>
  );
};

export default AuthenticatedLayout;
